import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LocationStrategy, HashLocationStrategy, DatePipe } from '@angular/common';
import { AppRoutes } from './app.routes';

import { AppComponent } from './app.component';
import { HeaderComponent } from './header/header.component';
import { BodyComponent } from './body/body.component';
import { SessionsComponent } from './sessions/sessions.component';
import { FooterComponent } from './footer/footer.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule, AngularFirestore } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';
import { ConferenceService } from './conference.service';
import { SpeakersComponent } from './speakers/speakers.component';
import { AgmCoreModule } from '@agm/core';
import { LocationComponent } from './location/location.component';
import { SafePipe } from './safe.pipe';
import { DateFormatPipe } from './date-format.pipe';
import { AboutComponent } from './about/about.component';



@NgModule({
    imports: [
        BrowserModule,
        FormsModule,
        AppRoutes,
        HttpClientModule,
        BrowserAnimationsModule,
        AngularFireModule.initializeApp(environment.firebase),
        AngularFirestoreModule,
        AgmCoreModule.forRoot({
            apiKey: 'AIzaSyCyLjYhJ2kcqjI7EXGDpuJIN5xkUpWF7hk'
        })
    ],
    declarations: [
        AppComponent,
        HeaderComponent,
        BodyComponent,
        SessionsComponent,
        FooterComponent,
        SpeakersComponent,
        LocationComponent,
        SafePipe,
        AboutComponent  
    ],
    providers: [ AngularFirestore,ConferenceService,DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
