import { Component, OnInit } from '@angular/core';
import { ConferenceService } from '../conference.service';
import { Observable } from 'rxjs';
import { Conference } from '../domain/conference';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.css']
})
export class AboutComponent implements OnInit {

  conference: Observable<Conference>;

  constructor(private conferenceService: ConferenceService) { }

  ngOnInit() {
    this.conference=this.conferenceService.getConference();
  }

}
