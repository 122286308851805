import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Speaker } from '../domain/speaker';
import { ConferenceService } from '../conference.service';

@Component({
  selector: 'app-speakers',
  templateUrl: './speakers.component.html',
  styleUrls: ['./speakers.component.css']
})
export class SpeakersComponent implements OnInit {

  speakers: Observable<Speaker[]>;

  constructor(private conferenceService: ConferenceService) { }

  ngOnInit() {
    this.speakers=this.conferenceService.getSpeakers();
  }

}
