import { Component, OnInit } from '@angular/core';
import { ConferenceService } from '../conference.service';
import { Conference } from '../domain/conference';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

   conference$: Observable<Conference>;

  constructor(private conferenceService: ConferenceService ,private router: Router) { }

  ngOnInit() {
   this.conference$=this.conferenceService.getConference();
  }

  scroll(id) {
    let el = document.getElementById(id);
    el.scrollIntoView();
  }
}
