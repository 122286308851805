import { Injectable, OnInit } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Conference } from './domain/conference';
import { Observable } from 'rxjs';
import { Session } from './domain/session';
import { Speaker } from './domain/speaker';

@Injectable({
  providedIn: 'root'
})
export class ConferenceService implements OnInit{

  conference: Observable<Conference>;
  doc: AngularFirestoreDocument<Conference>;

  constructor(private db: AngularFirestore) { 
     this.doc=this.db.doc<Conference>('conferences/2020');
     this.conference =this.doc.valueChanges();
  }

  ngOnInit(): void {
    
  }

  public getConference(): Observable<Conference> {
    return this.conference;
  }

  public getSessions(): Observable<Session[]>{
    return this.doc.collection<Session>('sessions',ref=>ref.orderBy('date')).valueChanges();
  }

  public getSpeakers(): Observable<Speaker[]>{
    return this.doc.collection<Speaker>('speakers').valueChanges();
  }

}
