import { Component, OnInit } from '@angular/core';
import { Conference } from '../domain/conference';
import { ConferenceService } from '../conference.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html',
  styleUrls: ['./location.component.css']
})
export class LocationComponent implements OnInit {

  conference$: Observable<Conference>;

  constructor(private conferenceService:ConferenceService) { }

  ngOnInit() {
    this.conference$=this.conferenceService.getConference()
  }

}
