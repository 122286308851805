import { Component, OnInit } from '@angular/core';
import { ConferenceService } from '../conference.service';
import { Observable } from 'rxjs';
import { Session } from '../domain/session';
import { Conference } from '../domain/conference';
import { DomSanitizer, SafeHtml, SafeStyle, SafeScript, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { DateFormatPipe } from '../date-format.pipe';
import { AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument ,} from '@angular/fire/firestore';
import { DatePipe } from '@angular/common';
import { Speaker } from '../domain/speaker';

@Component({
  selector: 'app-sessions',
  templateUrl: './sessions.component.html',
  styleUrls: ['./sessions.component.css']
})
export class SessionsComponent implements OnInit {

  conference$: Observable<Conference>;
  sessions: Session[];
  sessionsMap: Map<string, Session[]>;

  constructor(private db: AngularFirestore,private conferenceService: ConferenceService,private _datePipe:DatePipe) { }

  ngOnInit() {
    this.conference$ = this.conferenceService.getConference();
    this.conferenceService.getSessions().subscribe(data => {
    this.sessions = data;
    this.sessionsMap = new Map<string, Session[]>();
      if (this.sessions.length > 0) {
        let currentDay = 1;
        let currentDate = this.sessions[0].date;
        var sessionsDay: Array<Session> =[];
        for (let s of this.sessions) {
          if(s.speaker!=null){
            s.speaker.get()
                .then(doc => {
                  if (!doc.exists) {
                    s.speakerName="";
                  } else {
                    s.speakerName= " - Pr. " + doc.get('name.first')+" "+doc.get('name.last');
                  }
                })
                .catch(err => {
                  console.log('Error getting document', err);
                });
          }else{
            s.speakerName="";
          }
          if (this._datePipe.transform(currentDate.toDate(),"dd/MM/yyyy") === this._datePipe.transform(s.date.toDate(),"dd/MM/yyyy")) {
            sessionsDay.push(s);
          } else {
            this.sessionsMap.set("Día " + currentDay, sessionsDay);
            currentDay = currentDay + 1;
            currentDate = s.date
            sessionsDay = [];
            sessionsDay.push(s);
          }
        }
        this.sessionsMap.set("Día " + currentDay, sessionsDay);
      }
    });

  }



}
